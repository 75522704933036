import Ambiance from "./../assets/spta/sound/bo.mp3";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type AudioContextProps = {
  audio: HTMLAudioElement | undefined;
};

const initAudioContextPropsState: AudioContextProps = {
  audio: undefined,
};

const AudioContext = createContext<AudioContextProps>(
  initAudioContextPropsState
);

const useAudio = () => useContext(AudioContext);

const AudioContextProvider = ({ children }: PropsWithChildren) => {
  const [audio, setAudio] = useState<HTMLAudioElement>(new Audio(Ambiance));

  return (
    <AudioContext.Provider value={{ audio }}>{children}</AudioContext.Provider>
  );
};

export { AudioContextProvider, useAudio };
