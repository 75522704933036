import WorldElement from "./WorldElement";

import Hills1 from "./assets/spta/01.png";
import Hills2 from "./assets/spta/02.png";
import Hills3 from "./assets/spta/03.png";
import Panneaux from "./assets/spta/LAMP.png";

import Volcano from "./assets/volcano.png";

interface HillsProps {
  dinoSpeed: number;
}

const Hills = ({ dinoSpeed }: HillsProps) => (
  <>
    <WorldElement
      speed={dinoSpeed * 0.5}
      tileWidth={1722}
      tileHeight={281}
      tileUrl={Volcano}
    />
    <WorldElement
      speed={dinoSpeed * 1.5}
      tileWidth={5174}
      tileHeight={1200}
      tileUrl={Hills3}
    />
    <WorldElement
      speed={dinoSpeed * 1.75}
      tileWidth={5174}
      tileHeight={1200}
      tileUrl={Hills2}
    />
    <WorldElement
      speed={dinoSpeed * 2}
      tileWidth={5174}
      tileHeight={1200}
      tileUrl={Hills1}
    />
    <WorldElement
      speed={dinoSpeed * 2.2}
      tileWidth={5174}
      tileHeight={1200}
      tileUrl={Panneaux}
    />
  </>
);

export default Hills;
