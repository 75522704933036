import "./Leaderboard.css";
import { User } from "../models/User";

interface Props {
  user: User;
  updateUser: any;
  onRestartClicked: () => void;
}

export const Leaderboard = ({ user, onRestartClicked }: Props) => {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="inner">
          <h1>
            MAMMA MIA !!!!
            <br />
            BRAVISSIMO !!!!
          </h1>
          <p>{String(user.score)} prodotti per la nonna</p>
          <button onClick={onRestartClicked} className="button">
            Play again
          </button>
        </div>
      </div>
    </div>
  );
};
