import { useState } from "react";
import { Welcome } from "./pages/Welcome";
import { User } from "./models/User";
import App from "./App";
import { Leaderboard } from "./pages/Leaderboard";
import { AudioContextProvider } from "./context/audioContext";

const AppWrapper = () => {
  const [step, setStep] = useState<"welcome" | "game" | "score">("welcome");
  const [user, setUser] = useState<User>({ email: "", pseudo: "", score: 0 });

  const updateUser = (newUser: Partial<User>) => {
    setUser({ ...user, ...newUser });
  };

  return (
    <AudioContextProvider>
      {step === "welcome" && (
        <Welcome
          onPlayPressed={() => {
            setStep("game");
          }}
        />
      )}
      {step === "game" && (
        <App
          user={user}
          updateUser={updateUser}
          onGameFinished={() => setStep("score")}
        />
      )}
      {step === "score" && (
        <Leaderboard
          user={user}
          updateUser={updateUser}
          onRestartClicked={() => setStep("welcome")}
        />
      )}
    </AudioContextProvider>
  );
};

export default AppWrapper;
