import WorldElement from "./WorldElement";

import Skys from "./assets/spta/05.png";
import Clouds from "./assets/spta/cloud.png";

import "./Sky.css";

interface SkyProps {
  dinoSpeed: number;
}

const Sky = ({ dinoSpeed }: SkyProps) => (
  <div className="sky">
    
    <WorldElement
      speed={dinoSpeed * 0.1 + 0.05}
      tileWidth={5174}
      tileHeight={1200}
      tileUrl={Skys}
      alignment="top"
    />
    <WorldElement
      speed={dinoSpeed * 0.1 + 0.05}
      tileWidth={5162}
      tileHeight={844}
      tileUrl={Clouds}
      alignment="top"
    />
    <div className="sun" />
  </div>
);

export default Sky;
