import { User } from "./models/User";
import GameWrapper from "./GameWrapper";
import { useEffect, useRef, useState } from "react";

interface Props {
  user: User;
  updateUser: any;
  onGameFinished: () => void;
}

const App = ({ user, updateUser, onGameFinished }: Props) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const calendarRef = useRef<any | null>(null);

  useEffect(() => {
    setTimeout(function () {
      setIsPlaying(true);
    }, 8000);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        setSeconds((prevCounter) => prevCounter + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isPlaying]);

  const start = () => {
    if (calendarRef.current != null) {
      calendarRef.current.getApi().start();
    }
  };
  const stop = (gems: number) => {
    updateUser({ score: gems });
    onGameFinished();
    if (calendarRef.current != null) {
      calendarRef.current.getApi().pause();
    }
  };

  var toHHMMSS = (secs: string) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };

  return (
    <GameWrapper
      timeToDisplay={toHHMMSS(String(seconds))}
      onStartGameWrapper={() => start()}
      onFinishGameWrapper={(gems) => stop(gems)}
    />
  );
};

export default App;
